$button-medium: 0.5rem 2rem;
$button-small: 0.8rem 2rem;
$button-tiny: 0.4rem 2rem;

.buttons {
  display: flex;
  justify-content: space-between;
}

.button,
input[type="submit"] {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  color: $black;
  background: $manz;
  font-weight: 700;
  padding: $button-medium;
  text-align: center;
  border-radius: 30px;
  font-family: $font-condensed;
  transition: $transition-fast;

  &:hover {
    color: $black;
    background-color: darken($manz, 20%);
  }

  &.button--large {
    font-size: $body-font-size;
  }

  &.button--secondary {
    border-color: $secondary;
    border: 3px solid $secondary;
    color: $white;
    position: relative;
    transition: transform $time $bezier;
    margin-top: 0.5rem;
    word-break: keep-all;
    white-space: nowrap;

    //&:after {
    //  content: '';
    //  background-image: url("img/icons/arrow-black-right.svg");
    //  background-size: contain;
    //  background-repeat: no-repeat;
    //  position: absolute;
    //  width: 1.5rem;
    //  height: 1.5rem;
    //  right:-0.5rem;
    //  bottom: 0.35rem;
    //}

    &:hover {
      color: $black;
      background: $secondary;
    }
  }

  &.button--share {
    padding: $button-tiny;
    width: 18rem;
  }

  &.button--flex {
    display: flex;
    align-items: center;
  }
  &.button--testimonial {
    padding: $button-medium;

    @include breakpoint(medium up) {
      margin-bottom: 2rem;
    }
  }

  &.button--nobreak {
    padding: 0.8rem 1.8rem;
    width: 100%;
  }
}

.button__icon {
  display: flex;
  align-items: center;
}

.button__icon--up {
  transform: rotate(180deg);
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button__block {
  margin: $general-margin * 0.5 0 $general-margin 0;
}

.button__block--center {
  width: 100%;
  button {
    margin: 0 auto;
    display: block;
  }
}

.download__icon {
  margin-left: 1rem;
  svg {
    width: 1rem;
    height: 100%;
    transform: translateY(2px);

    @include breakpoint(medium) {
      width: 1.25rem;
    }
  }
}
