@use "sass:math";

%transition-ease {
  transition: 0.2s ease;
}

// Sticky Footer
// =============

body {
  display: flex;
  flex-direction: column;
  // I would rather use min-height, but IE11 crashes the party
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  min-height: 100vh;
  &.nav-is-open {
    //position: fixed;
  }
  -webkit-overflow-scrolling: touch;

  &.page--home {
    background-color: $atlantis;
  }
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;
  //background-color: $light-gray;
  //@include resprop("padding-top", (rem-calc(76px), rem-calc(78px), rem-calc(172px)));

  //padding-top: $general-margin;

  &.main--invert {
    background-color: $white;
  }

  .page--home & {
    padding-top: 0;
    background-color: $white;
  }
}

// General elements / behavior styling
// ===================================

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.section {
  position: relative;
  //@include sectionPadding(
  //  "padding-top",
  //  ($general-padding * 0.5, $general-padding * 0.8, $general-padding)
  //);
  //@include sectionPadding(
  //  "padding-bottom",
  //  ($general-padding * 0.5, $general-padding * 0.8, $general-padding)
  //);

  padding: 2rem 0;

  @include breakpoint(medium) {
    padding: 4rem 0;
  }

  &.section--no-paddings {
    padding-bottom: 0;
  }
}

.align-center--small {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.align-center--medium {
  @include breakpoint(medium down) {
    justify-content: center;
  }
}

.grid-container--margin {
  @include resprop("margin-bottom", ($general-margin, $general-margin * 1.5));
}

.grid-container--margin-half {
  @include resprop(
    "margin-bottom",
    (math.div($general-margin, 3), $general-margin * 0.5)
  );
}

.breadcrumb__link {
  display: block;
  margin-bottom: $general-block-margin;

  &:before {
    position: relative;
    content: url("img/icons/arrow-left.svg");
    margin-right: 0.5rem;
  }
}

.show-for-medium-down {
  display: none;
  @include breakpoint(medium down) {
    display: block;
  }
}

.flex-ordering {
  @include breakpoint(small only) {
    .flex-order--1 {
      order: 1;
    }

    .flex-order--2 {
      order: 2;
    }
  }
}

.cell--right {
  @include breakpoint(medium) {
    margin-left: auto;
  }
}

.cell--left {
  @include breakpoint(medium) {
    margin-right: auto;
  }
}

.margin-top {
  margin-top: $general-margin;
}

// 404
.page--notfound {
  position: relative;
  width: 100%;
  height: 100vh;
}
.page--background-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(19, 50, 45, 0.5);
    mix-blend-mode: overlay;
    z-index: 0;
  }
}

.notfound-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  z-index: 100;

  h1 {
    line-height: 0.9;
  }
  span {
    display: block;
    transform: translate(10px, 0);

    @include breakpoint(medium) {
      transform: translate(20px, 0);
    }
    font-weight: $semi-bold;
  }
}

.flex-order-1 {
  @include breakpoint(small only) {
    order: 1;
  }
}

.flex-order-2 {
  @include breakpoint(small only) {
    order: 2;
  }
}

.z10 {
  z-index: 10;
  position: relative;
}
