.factslider {
  //padding-bottom: 5rem;
  background-color: $deep-teal;
  //z-index: 400;

  &:before {
    content: "";
    background: #0000
      linear-gradient(
        180deg,
        #17403b 0%,
        #17403b 26%,
        #17403b 32%,
        #17403b 35%,
        #17403b 28%,
        #17403b 35%,
        #17403b 55%,
        $atlantis 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    bottom: -21rem;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: 0;
  }
}

.factslider__slides {
  overflow: hidden;
  position: relative;
}

.factslider__slide {
  position: relative;
  z-index: 100;
}

.factslider__slider {
  display: flex;
}

.facts {
  position: relative;
  //height: 120vh;
  overflow: hidden;
  background: $atlantis;

  @include breakpoint(medium) {
    padding-bottom: 10rem;
  }

  @include breakpoint(large) {
    padding-bottom: 25rem;
  }

  &:before {
    content: "";
    background: #0000
      linear-gradient(
        180deg,
        #17403b 0%,
        #17403b 26%,
        #17403b 32%,
        #17403b 35%,
        #17403b 28%,
        #17403b 35%,
        #17403b 36%,
        #17403b00 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: 20;
  }
}

.facts__background {
  position: absolute;
  background-image: url("img/factsbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 47%;
  transform: translateX(10%);
  width: 100%;
  height: 100%;
  overflow: visible;
}

.facts__container {
  position: relative;
  display: flex;
  z-index: 100;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 5vh;
  width: 100%;
  height: 100%;
  @include breakpoint(medium) {
    overflow: hidden;

    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 0;
  }
}

.fact {
  flex: 0 1 50%;

  @include breakpoint(medium) {
    flex: 0 1 50%;
  }
}

.fact__content-wrapper {
  display: flex;
}

.fact__content {
  max-width: 80%;
}

$fact-divider-length: 30rem;

.fact__divider {
  height: $fact-divider-length;
  width: 2px;
  background: $white;
  //margin-right: 2rem;
  position: relative;
  display: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    transform: translate(calc(-50% + 1px), 0);
  }

  @include breakpoint(medium) {
    display: block;
  }

  &.fact__divider--large {
    height: $fact-divider-length;
  }

  &.fact__divider--medium {
    height: $fact-divider-length * 0.8;
  }

  &.fact__divider--small {
    height: $fact-divider-length * 0.6;
  }
}

@keyframes factsAnimation {
  from {
    transform: translateX(150px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.4;
  }
}

@keyframes factsAnimationMobile {
  from {
    transform: translateY(-150px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.2;
  }
}

[data-metric] {
  opacity: 0;
  text-align: right;
  font-size: 150px;
  line-height: normal;
  position: absolute;
  width: 100%;

  @include breakpoint(medium) {
    text-align: left;
    font-size: 125px;
  }

  @include breakpoint(large) {
    font-size: 250px;
  }
  &[data-current] {
    animation: 0.5s ease-out 0s 1 normal forwards running factsAnimation;
  }

  @include breakpoint(small only) {
    top: -300px;
    &[data-current] {
      animation: 0.5s ease-out 0s 1 normal forwards running factsAnimationMobile;
    }
  }

  &[data-metric="570 Mio."] {
    font-size: 120px;

    @include breakpoint(medium) {
      font-size: 70px;
    }
    @include breakpoint(large) {
      font-size: 150px;
    }

    @include breakpoint(small only) {
      line-height: 1;
    }
  }

  &[data-metric="172m"] {
    @include breakpoint(large) {
      font-size: 200px;
    }
  }
}

.facts__wrapper {
  height: 100%;
  width: 100%;
}
