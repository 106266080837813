.footer {
  background-image: url("img/wave.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right 10%;
  position: relative;

  @include breakpoint(medium) {
    padding-top: 5rem;
    margin-top: -6rem;
  }
}

.footer__element {
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.footer__wrapper {
  position: relative;
  //height: 100%;
  //background: transparent linear-gradient(180deg, #3D8062 0%, #13322D 100%) 0% 0% no-repeat padding-box;
  z-index: 10;
  padding: 10rem 0 0 0;

  @include breakpoint(large) {
    padding: 12rem 0 4rem 0;
  }
}

.footer__meta {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    a {
      color: $white;
    }
  }
}
