$bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
$time: 300ms;
@mixin animated($time: 300ms, $fillmode: both, $count: 1) {
  animation-duration: $time;
  animation-fill-mode: $fillmode;
  animation-iteration-count: $count;
}

$grid-gap: rem-calc(20px);
$grid-gap-small: rem-calc(16px);

$transition-slow: all 0.4s ease-in-out;
$transition: all 0.25s ease-in-out;
$transition-fast: all 0.15s ease-in-out;

// calc(2 * (1.5vw + 1vh - 1vmin))

// Increase when decreasing viewport
// calc(300px - 20vw);
