// Article List

.articles__list:not(.articles__list--pages) {
  transform: translate(0, -3rem);

  @include breakpoint(medium) {
    transform: translate(0, -4rem);
  }
}

// Article Teaser element
.article-teaser__element--page {
  h3 {
    font-size: 1.82813rem;
    hyphens: auto;
    font-weight: 400;
    line-height: 1;
  }
}

.article-teaser-content {
  background: $white;
  padding-top: 2rem;
  padding-right: 2rem;

  transform: translate(0, -3rem);

  @include breakpoint(medium) {
    transform: translate(0, -4.25rem);
  }
  width: 80%;
}

.article-teaser__image {
  transition: $transition;
}

.article-teaser__image-wrapper {
  overflow: hidden;
  .article-teaser__element:hover & {
    .article-teaser__image {
      transform: scale(1.1);
    }
  }
}

// article detail

.article {
  background: $white;
}

.article__image-container {
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;

  @include breakpoint(medium) {
    height: 40vh;
  }

  @include breakpoint(large) {
    height: 60vh;
  }
}

.article__image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.article__wrapper {
  position: relative;
  background: $white;
  transform: translate(0, 0);
  margin-top: $general-margin;

  @include breakpoint(medium) {
    margin-top: 0;
    padding-top: 4rem;
    transform: translate(0, -4rem);
  }
}

.article-main__title {
  hyphens: auto;

  @include resprop(
    "font-size",
    ($h1-font-size * 0.5, $h1-font-size * 0.75, $h1-font-size * 0.75)
  );
  //margin-top: $general-margin;
  @include breakpoint(medium) {
    //margin-top: 0;
    margin-bottom: 2rem;
  }
}

.article__info {
  position: relative;
  color: $black;
  z-index: 20;
  text-align: right;
  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 0;
    margin-right: 2rem;
  }

  @include breakpoint(large) {
    margin-right: 4rem;
  }
}

.article--divider {
  background-color: $manz;
  height: 0.05rem;
  width: 100%;
  margin: 2rem 0 3rem 0;
}

// Pagination

.paginator-container {
  margin-bottom: $general-margin * 3;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-arrow {
  &.pagination-arrow--right {
    margin-left: $general-block-margin;
    svg {
      transform: rotate(-180deg) translateY(-4px);
    }
  }
  &.pagination-arrow--left {
    margin-right: $general-block-margin;
    svg {
      transform: translateY(4px);
    }
  }
}

.plugin__richtext--highlighted {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $white;
  background-color: $deep-teal;

  p:last-child {
    margin-bottom: 0;
  }
}
