details {
  --_ms: 300ms;
  height: var(--collapsed);
  overflow: hidden;
  transition: height var(--_ms) ease-out;
  border-bottom: 1px solid $black;
}

details[open] {
  height: var(--expanded);
}

.details--first {
  border-top: 1px solid $black;
}

summary {
  cursor: pointer;
  min-height: 4.12em;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-weight: 400;
    margin-bottom: 0;
  }
}

.details__marker {
  --_size: 1.4rem;
  --_thickness: 0.25rem;
  color: $black;
  width: var(--_size);
  height: var(--_size);
  border: 0;
  position: relative;
}

.details__marker span {
  position: absolute;
  transition: 300ms;
  background: $black;
}

.details__marker span:first-child {
  top: 0;
  bottom: 0;
  width: var(--_thickness);
  left: calc(50% - 0.125rem);
}

.details__marker span:last-child {
  left: 0;
  right: 0;
  height: var(--_thickness);
  top: calc(50% - 0.125rem);
}

details[open] .details__marker span {
  transform: rotate(90deg);
}

details[open] .details__marker span:last-child {
  left: 50%;
  right: 50%;
}
