.form-group {
  background: $white;
  margin-bottom: $general-block-margin;
  display: flex;
  border-radius: 30px;

  fieldset,
  input {
    width: 100%;
  }

  &.form-group--white {
    box-shadow: 0px 3px 6px #00000029;
  }
}

input,
fieldset {
  border: none;
}

fieldset {
  padding: 0.25rem 1rem;

  &.form-fieldset--right {
    padding-left: 0;
  }
}

input {
  padding: 0;
  margin: 0.5rem 0;
  border-radius: 0;
  &::placeholder,
  & {
    color: $black;
    font-family: $font-condensed;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

.form-element--border-right {
  @include breakpoint(medium) {
    border-right: 1px solid rgba(112, 112, 112, 0.33);
    padding-right: 1rem;
  }
}

.form-group__next {
  button {
    cursor: pointer;
    margin: 0 auto;
    display: block;
  }
}

.form-title {
  margin-bottom: $general-margin;
}

//CSS

label *,
label *::before,
label *::after {
  box-sizing: content-box !important;
}

label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

label span {
  line-height: 1.54;
  font-size: 1rem;
  font-family: inherit;
}

label {
  display: table;
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

label input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: rgba(241, 245, 248, 0);
  transition: background 250ms;
  border: 2px solid rgba(229, 235, 80, 1);
  border-radius: 3rem;
}

label input[type="radio"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgba(241, 245, 248, 0);
  transition: background 250ms;
  border: 2px solid rgba(229, 235, 80, 1);
  border-radius: 2rem;
}

label input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.34rem;
  top: 0.15rem;
  width: 0.25rem;
  height: 0.43rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

label input[type="radio"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.25rem;
  top: 0.25rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 1);
  transition: background 250ms;
}

label input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

label input:checked ~ .b-input::after {
  display: block;
}

label:hover input ~ .b-input,
label input:focus ~ .b-input {
  background: rgba(229, 235, 80, 0.29);
}

label input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgba(229, 235, 80, 0.29);
}

label input:checked ~ .b-input {
  background: rgba(229, 235, 80, 1);
  border-color: rgba(229, 235, 80, 1);
}

label input[type="checkbox"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

label input[type="radio"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

label input[type="radio"]:disabled ~ .b-input::after {
  background: rgba(135, 149, 161, 1);
}

label input:checked:focus ~ .b-input,
label:hover input:not([disabled]):checked ~ .b-input {
  background: rgba(229, 235, 80, 1);
  border-color: rgba(229, 235, 80, 1);
}

label .b-input::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: rgba(229, 235, 80, 1);
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

label input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

label input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

label .b-input::before {
  visibility: hidden;
}

label input:focus + .b-input::before {
  visibility: visible;
}

label:first-child .b-input::before {
  visibility: hidden;
}
