// Media Slider

.media-slider__container {
  margin-top: $general-margin * 0.5;

  @include breakpoint(medium) {
    margin-top: $general-margin;
  }
}

.media-slider__slide {
  position: relative;
}

.media-slider__slides {
  //max-width: 1000px;
  overflow: hidden;
  position: relative;
}

.media-slider__slider {
  display: flex;
}

.media-slider__image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.media-slider__image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .image__caption {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0.5rem 0;
    background: $white;
    max-width: 100%;
  }
}

.media-slider__thumbs {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);

  &.media-slider__thumbs--facts {
    left: 0;
    position: relative;
    transform: none;
    bottom: auto;
    justify-content: flex-start;

    a[data-thumb] {
      &::before {
        background-color: $white;
      }
    }
  }

  @include breakpoint(medium) {
    bottom: -4rem;
  }

  a[data-thumb] {
    position: relative;
    height: 30px;
    width: 30px;
    margin: 0;
    padding: 0;
    background: none;
    border: 0 none;
    outline: none;
    cursor: pointer;

    &::before {
      background-color: $wattle;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      margin-top: -4px;
      margin-left: -4px;
      transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border-radius: 50%;
      opacity: 0.7;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: scale(0);
      transition:
        transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border: 3px solid $wattle;
      opacity: 0;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
    }

    &[data-current] {
      opacity: 1;
      position: relative;
      transition: all ease-in-out 200ms;

      &::after {
        transform: scale(0.8);
        opacity: 1;
      }

      &::before {
        background: $wattle;
        opacity: 1;
      }
    }
  }
}

.media-slider__go-wrapper {
  position: absolute;
  top: 45%;
  width: 3rem;
  height: 3rem;

  display: none;

  &.media-slider__go-wrapper--left {
    position: absolute;
    left: -3rem;
  }

  &.media-slider__go-wrapper--right {
    position: absolute;
    right: -6rem;
  }
}

.media-slider__go {
  display: block;
  width: 3rem;
  height: 3rem;

  &:after {
    position: absolute;
    content: url("img/icons/arrow-left.svg");
  }

  &:before {
    content: "";
    position: absolute;
    border: 5px solid $wattle;
    border-radius: 50%;
    padding: 1.25rem;
    transform: translate(-50%, -50%);
    transition: all 200ms ease-in-out;
  }

  &[data-go="-1"] {
    &:after {
      transform: translate(-60%, -38%);
    }
  }

  &[data-go="1"] {
    &:after {
      content: url("img/icons/arrow-right.svg");
      transform: translate(-40%, -38%);
    }
  }

  &:hover {
    &:before {
      border: 5px solid $wattle;
      padding: 2rem;
    }
  }
}
