@font-face {
  font-family: "BarlowRegular";
  src: url("fonts/Barlow-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BarlowBold";
  src: url("fonts/BarlowCondensed-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

$font-main: "BarlowRegular", sans-serif;
$font-condensed: "BarlowBold", sans-serif;

$body-font-size-tiny: rem-calc(14);
$body-font-size-small: rem-calc(15);
$body-font-medium: rem-calc(20);
$body-font-size: rem-calc(18);
$body-font-size-large: rem-calc(25);
$body-line-height: 1.5;
$headings-line-height: 1.2;

$h1-font-size: rem-calc(70px);
$h2-font-size: rem-calc(45px);
$h3-font-size: rem-calc(35px);
$h4-font-size: rem-calc(28px);
$h5-font-size: rem-calc(25px);

$regular: 400;
$semi-bold: 500;
$bold: 700;

$font-weight-main: 500;

$general-block-margin: 1.5rem;
$general-margin: rem-calc(40px);
$general-padding: 2rem;
$general-border-style: dashed;

%body-font {
  font-family: $font-main;
  font-weight: $font-weight-main;
  font-style: normal;
}

%h1-font-size {
  @include resprop(
    "font-size",
    ($h1-font-size * 0.5, $h1-font-size * 0.75, $h1-font-size)
  );
}

%h2-font-size {
  @include resprop(
    "font-size",
    ($h2-font-size * 0.7, $h2-font-size * 0.8, $h2-font-size)
  );
}

%h3-font-size {
  @include resprop("font-size", ($h3-font-size * 0.8, $h3-font-size));
}

%h4-font-size {
  @include resprop(
    "font-size",
    ($h4-font-size * 0.7, $h4-font-size * 0.8, $h4-font-size)
  );
}

%h5-font-size {
  @include resprop("font-size", ($h5-font-size * 0.9, $h5-font-size));
}

%menu-font-styles {
  font-family: $font-condensed;
  font-weight: $bold;
  font-size: $body-font-medium;
  text-transform: uppercase;
}

%small-title-font-styles {
  font-family: $font-condensed;
  font-weight: $bold;
  font-size: $body-font-medium;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  margin-bottom: $general-block-margin;
  font-family: $font-condensed;
  line-height: $headings-line-height;
}

h1,
.h1 {
  @extend %h1-font-size;
}

h2,
.h2 {
  @extend %h2-font-size;
  line-height: $headings-line-height;

  .plugin__richtext & {
    @include resprop("font-size", ($h2-font-size * 0.65));
    margin-bottom: $general-block-margin * 2;
  }
}

h3 {
  @extend %h3-font-size;

  .plugin__richtext & {
    @include resprop("font-size", ($h3-font-size * 0.6));
    margin-bottom: $general-block-margin;
  }
}

h4 {
  @extend %h4-font-size;
  margin-bottom: $general-block-margin * 0.6;
}

h5 {
  @extend %h5-font-size;
  margin-bottom: $general-block-margin * 0.6;
  margin-top: 0;
}

// to be extended where it is
%indent-list-items {
  margin-left: 1em;
}

body {
  font-family: $font-main;
  font-size: $body-font-size-small;
  line-height: $body-line-height;
  color: $black;

  @include breakpoint(large up) {
    font-size: $body-font-size;
  }
}

p,
form,
table {
  margin-bottom: $general-block-margin;
}

ul,
ol {
  margin-bottom: $general-block-margin;
  padding-left: 1rem;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

strong {
  font-weight: $bold;
}

address {
  font-style: normal;
}

a:not(.button) {
  color: $wattle;
  font-weight: $semi-bold;
}

.plugin__richtext {
  ul,
  ol {
    margin-left: 1.5em;

    li {
      padding-left: 0.2em;
    }
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $general-block-margin;
    margin-bottom: $general-block-margin;

    @include breakpoint(medium) {
      margin-top: $general-block-margin * 2;
    }

    &[style="text-align:center"] {
      max-width: 80%;
      margin: $general-block-margin * 2 auto;
      line-height: 1.4;

      @include breakpoint(medium) {
        margin: $general-block-margin * 2.5 auto;
      }
    }
  }

  //.page--home & {
  //  @include breakpoint(medium) {
  //    padding: 0 3rem;
  //  }
  //}
}

//.plugin--padding {
//  .page--home & {
//    @include breakpoint(medium) {
//      padding: 0 3rem;
//    }
//  }
//}

.text--white {
  color: $white;
}

.text--margin {
  margin-bottom: $general-margin;
}

.text--oversized {
  font-size: 80px;
}

.text--large {
  line-height: normal;
  font-size: $body-font-medium;

  @include breakpoint(medium) {
    font-size: $body-font-size-large;
  }
}
.text--medium {
  font-size: $body-font-medium;
}

.text--small {
  font-size: $body-font-size-small;
}

.text--tiny {
  font-size: $body-font-size-tiny;
}

.text--ultra-large {
  @include resprop(
    "font-size",
    (rem-calc(300px) * 0.6, rem-calc(300px) * 0.75, rem-calc(300px))
  );
}

.text--bold {
  font-weight: $bold;
}

.text-semibold {
  font-weight: $semi-bold;
}

.text--yellow {
  color: $manz;
}

.text--yellow-darken {
  color: $wattle;
}

.text--uppercase {
  text-transform: uppercase;
}
.text--condensed {
  font-family: $font-condensed;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.back-link {
  a img {
    transform: rotate(180deg);
  }

  margin-bottom: $general-block-margin;
}

.text--marked {
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0.1rem 0.5rem;
  line-height: 1.6;
  background: $manz;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    $manz 60%,
    $manz 100%
  );
  font-family: $font-condensed;
}
