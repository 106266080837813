.home-intro {
  position: relative;
}

.river-path {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;
  width: 100%;
  display: none;
  z-index: 0;

  @include breakpoint(large) {
    display: block;
  }
}

.home-intro__container {
  z-index: 30;
  position: relative;
  //overflow: hidden;

  @include breakpoint(medium down) {
    height: 100% !important;
    overflow: hidden;
  }
}

// Topics

.intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  //background-color: rgba(23, 64, 59, 0.3);
}

.intro-text {
  flex: 1 1 100%;
  //background-color:$racing-green;
  //opacity: 0.5;
}

.intro--first {
  @include breakpoint(medium) {
    //transform: translateY(-20px);
  }

  @include breakpoint(large) {
    padding-top: 9vh;
    transform: translateY(-200px);
  }
}

.topic {
  padding: 2rem 2rem;
  display: flex;

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
  }

  &.intro--second {
    //background-color:$viridian;
    //opacity: 0.5;
    flex: 1 1 100%;

    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    @include breakpoint(large) {
      transform: translateY(-100px);
    }
  }

  &.intro--third {
    flex: 1 1 100%;

    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    @include breakpoint(large) {
      transform: translateY(100px);
    }
  }

  &.intro--fourth {
    flex: 1 1 100%;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    @include breakpoint(large) {
      align-items: flex-end;
    }
  }
}

.topic__element {
  display: flex;
  flex: 0 1 60%;

  @include breakpoint(large) {
    justify-content: center;

    .intro--second & {
      flex: 0 1 40%;
    }

    .intro--third & {
      flex: 0 1 50%;
    }

    .intro--fourth & {
      flex: 0 1 70%;
    }

    &.topic__element--right {
      justify-content: flex-start;
    }
    &.topic__element--left {
      justify-content: flex-end;
    }
  }

  @include breakpoint(small only) {
    &:nth-child(odd) {
      order: 2;
      text-align: right;
    }
  }

  @include breakpoint(medium) {
    .intro--third & {
      order: 1;
    }
  }
}

.topic__content {
  text-align: center;
  z-index: 10;

  @include breakpoint(medium) {
    margin-top: 0;
    order: unset;
    text-align: left;

    .intro--third & {
      text-align: right;
    }
  }

  @include breakpoint(large) {
    width: 23rem;
    text-align: center;
    //margin-right: 4rem;

    .intro--third & {
      text-align: center;
    }
  }
}

.topic__image-container {
  position: relative;
  flex: 0 1 50%;
  height: 30vh;
  display: flex;

  @include breakpoint(medium) {
    order: unset;
  }
}

.topic__image-wrapper {
  flex: 1;
  position: relative;

  @include breakpoint(small only) {
    min-height: 200px;
  }

  div {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  @include breakpoint(large) {
    .intro--third & {
      div {
        left: 50%;
      }
    }
  }
}

.topic__image {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 40vh;

  &.topic__image--sunumbrella {
    transform: translate(5%, -5%) scale(1);

    @include breakpoint(medium) {
      transform: translate(-20%, -10%) scale(1);
    }

    @include breakpoint(large) {
      transform: translate(10%, 20%) scale(1.2);
    }
  }

  &.topic__image--topo-2 {
    transform: translate(0, -20%) scale(0.8);

    @include breakpoint(medium) {
      transform: translate(-20%, -20%) scale(0.8);
    }

    @include breakpoint(large) {
      transform: translate(10%, -20%) scale(0.8);
    }
  }

  &.topic__image--beaver {
    transform: translate(20%, 0) scale(0.5);

    @include breakpoint(medium) {
      transform: translate(0%, -20%) scale(0.6);
    }

    @include breakpoint(large) {
      transform: translate(30%, -10%) scale(0.6);
    }
  }

  &.topic__image--fish {
    transform: translate(-20%, -20%) scale(0.5);

    @include breakpoint(medium) {
      transform: translate(-30%, -10%) scale(0.6);
    }

    @include breakpoint(large) {
      transform: translate(0%, 10%) scale(0.6);
    }
  }

  &.topic__image--water {
    width: 50vh;
    transform: translate(0, -15%) scale(1);
    @include breakpoint(medium) {
      transform: translate(-5%, 0) scale(1);
    }
    @include breakpoint(large) {
      transform: translate(-25%, -10%);
    }
  }
}

.home--main {
  position: relative;
  &:after {
    content: url("img/topo-white.png");
    top: 0;
    left: 5%;
    position: absolute;
    max-width: 100%;
    z-index: 30;
    opacity: 0.7;
    pointer-events: none;
  }
}
