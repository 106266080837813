$black: #202020;
$white: #ffffff;

// main colors

$racing-green: #13322d;
$deep-teal: #17403b;
$viridian: #3d8062;
$atlantis: #8bc147;
$conifer: #b7d253;
$manz: #e5eb50;
$wattle: #d1d64b;

$green: $atlantis;

$light-gray: #f8f8f8;
$medium-gray: #efefef;
$dark-gray: #1d1d1d;
$menu-color: $dark-gray;

$primary: $black;
$secondary: $atlantis;
$headings-color: $black;

$primary-gray: $light-gray;
$secondary-gray: $medium-gray;

$box-shadow: 0 8px 17px -18px rgba(32, 32, 32, 0.6);

$deep-teal-rbga: rgba(19, 50, 45, 0.7);

// gradients

%page-teaser-gradient {
  position: relative;
  background-color: $deep-teal;

  &:before {
    content: "";
    background: transparent linear-gradient(0deg, #13575703 0%, #12304a5c 100%)
      0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;

    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    opacity: 0.8;
  }

  &:after {
    content: "";
    background: transparent
      linear-gradient(180deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
      padding-box;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
  }
}

$page-header-gradient: transparent
  linear-gradient(0deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
  padding-box;

.f3cc {
  --f3cc-accept-background: #245142;
  --f3cc-accept-foreground: #fff;
}

.f3cc .f3cc-button.accept {
  color: white;
}
