.image-comparison {
  background-color: $deep-teal;
  padding-bottom: $general-margin;

  @include breakpoint(medium) {
    padding-bottom: $general-margin * 2;
  }
}

.image-comparison__container {
  position: relative;
  z-index: 100;
}

.slider-with-custom-relative-handle {
  --handle-size: clamp(400px, 8.5vw, 100px);
  --handle-opacity: 1;
  --handle-opacity-active: 0;
}

img-comparison-slider {
  margin: 0 auto;
  --hint-size: 300px;
  --hint-opacity: 1;
  --divider: 5px solid #e5eb50;

  &:focus {
    outline: none;
  }
}
.custom-relative-handle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);
}
