.inat-widget {
  .article-teaser__image-wrapper {
    max-height: 8rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  .article-teaser-content {
    padding-top: 1rem;
    padding-right: 1rem;
    transform: translate(0, -1rem);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    & > * {
      margin-bottom: 0;
    }
  }

  .button--read-more {
    font-weight: normal;
    font-size: 0.8rem;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin-right: auto;
  }
}
.inat-widget__user {
  display: flex;
  align-items: center;
  font-size: small;

  gap: 0.5rem;
  margin-left: auto;
}
.inat-widget__user-icon {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}
