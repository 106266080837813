// Nav configs
$sticky-header-height: rem-calc(70);
$sticky-header-height-large: 5rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;

$nav-toggle-transition: 0.25s ease-in-out;

// Navigation
// ==========

.header {
  //padding-top: $sticky-header-height-large;
  background: linear-gradient(
    0deg,
    rgba(19, 50, 45, 0) 0%,
    rgba(19, 50, 45, 0.4) 35%,
    rgba(19, 50, 45, 0.8) 100%
  );
  position: fixed;
  top: 0;
  transition: $transition-slow;
  z-index: z-index("header");
  width: 100%;

  .nav-is-open & {
    background: $deep-teal;
  }

  .scrolled & {
    background: $deep-teal-rbga;
  }
}

.sticky-header {
  //top: 0;
  //left: 0;
  width: 100%;
  transition: 0.2s ease box-shadow;

  .nav-is-open & {
    position: fixed;
    background: $deep-teal;

    height: 100%;
    box-shadow: none;
    overflow-y: scroll;
  }

  .page--headless & {
    //background: $light-gray;
  }
}

.navigation {
  position: relative;
  display: flex;
  //align-items: flex-end;
  justify-content: space-between;
  color: $white;
  align-items: center;
  padding-bottom: 0.5rem;

  @include breakpoint(medium down) {
    padding: 1rem 0;
  }

  @include breakpoint(large) {
    align-items: normal;
    padding-bottom: 2rem;
  }
  .navigation--center {
    position: relative;
  }
}

.navigation__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navigation__logo {
  max-width: 150px;

  @include breakpoint(medium) {
    max-width: 250px;
  }

  @include breakpoint(large) {
    width: 300px;
    max-width: unset;

    img {
      display: block;
      transform: translateY(20px);
    }

    //  opacity: 0;
    //}
  }
}

.navigation__item-logo {
  display: block;
  height: auto;
}

.navigation__container {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  transition:
    transform 0.4s ease-in-out,
    opacity 200ms ease-in-out;
  transform: translateY(-20px);
  z-index: 100;
  margin-top: 3rem;

  @include breakpoint(large) {
    position: relative;
    display: block;
    height: 100%;
    align-items: center;
    opacity: 1;
    transform: translateY(0);
    margin-top: 0;
    width: auto;
  }

  .nav-is-open & {
    transform: translateY(10px);
    opacity: 1;
    display: block;
    padding-bottom: 5rem;
    background: transparent;
  }
}

.navigation__container--overlay {
  width: 100%;
  height: 100%;

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
}

.navigation__list {
  position: relative;
  transition: transform 0.4s ease-in-out;
  padding-left: 0;
  .nav-is-open & {
    opacity: 1;
    visibility: visible;
    margin-bottom: 1rem;
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }
}

.rotate {
  transform: rotate(180deg);
}

.navigation__item {
  list-style-type: none;
  margin: 0;

  &.navigation__item--cte {
    text-align: left;
    margin-top: 0.5rem;
  }

  @include breakpoint(large) {
    margin-bottom: 0.1rem;
    text-align: center;
    padding: 0;
    margin-left: 2rem;
    &.navigation__item--cte {
      display: none;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
}

.navigation__link {
  position: relative;
  display: block;
  color: $white;
  opacity: 0;
  transition-delay: 500ms;
  width: fit-content;
  padding: 0.3rem 0 0.1rem;
  text-align: left;

  @extend %menu-font-styles;

  &:after {
    content: "•";
    position: absolute;
    bottom: -2.25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;

    transition: $transition-fast;

    .nav-is-open & {
      position: absolute;
      bottom: -13px;
      left: -10px;
      margin-right: 1rem;
    }
  }

  .active &,
  &:hover {
    .nav-is-open & {
      left: 1rem;
    }

    &:after {
      color: $manz;
    }
  }

  @include breakpoint(large) {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    color: $white;
    width: auto;
    position: relative;
    padding: 0 0.25rem;
  }
  .nav-is-open & {
    opacity: 1;
  }
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// Navigation Mobile
// =================

$size: 30px;
$default-color: $manz;
$hover-color: $manz;

.animate {
  transition: all 0.3s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.nav__trigger {
  width: $size;
  height: $size;
  margin: auto;
  cursor: pointer;
  position: relative;
  @extend .animate;
  display: block;

  @include breakpoint(large) {
    display: none;
  }

  //&:hover {
  //
  //  &:before {
  //    opacity: 0;
  //    transform: rotate(45deg);
  //  }
  //
  //  &:after {
  //    opacity: 1;
  //    transform: rotate(0deg);
  //  }
  //
  //  .nav__icon {
  //    transform: rotate(45deg);
  //
  //    .dot {
  //      background-color: $hover-color;
  //
  //      &:nth-child(1),
  //      &:nth-child(3),
  //      &:nth-child(7),
  //      &:nth-child(9) {
  //        opacity: 0;
  //      }
  //
  //      &:nth-child(2),
  //      &:nth-child(8) {
  //        opacity: 1;
  //      }
  //    }
  //  }
  //}

  &:active {
    opacity: 0.8;
  }
}

.nav__icon {
  width: $size;
  height: $size;
  position: absolute;
  top: 0;
  right: 0;
  @extend .animate;

  .dot {
    background-color: $default-color;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    @extend .animate;

    &:nth-child(1) {
      top: 0;
      left: 0;
      opacity: 0;
    }

    &:nth-child(2) {
      top: 0;
      left: 38%;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 0;
      left: 76%;
      opacity: 0;
    }

    &:nth-child(4) {
      top: 38%;
      left: 0;
    }

    &:nth-child(5) {
      top: 38%;
      left: 38%;
    }

    &:nth-child(6) {
      top: 38%;
      left: 76%;
    }

    &:nth-child(7) {
      top: 76%;
      left: 0;
      opacity: 0;
    }

    &:nth-child(8) {
      top: 76%;
      left: 38%;
      opacity: 0;
    }

    &:nth-child(9) {
      top: 76%;
      left: 76%;
      opacity: 0;
    }
  }
}

.navigation__address {
  display: none;
  flex-direction: column;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  text-align: left;
  border-top: 1px solid darken($medium-gray, 10%);

  h4 {
    color: $white;
    margin-bottom: 0.5rem;
  }

  span,
  a {
    font-size: $body-font-size-small;
    color: $white;
    display: block;
  }

  .nav-is-open & {
    display: flex;
  }
}

// navigation--right
// =================

.navigation__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.languages-mobile {
  display: none;
  flex-direction: row;
  margin-top: 1rem;
  text-align: left;
  list-style: none;
  padding-left: 0;
  border-top: 1px solid darken($medium-gray, 10%);
  padding-top: 1rem;

  margin-bottom: 0;

  .nav-is-open & {
    display: flex;
  }
}

.languages-mobile__link {
  padding-right: 1rem;
  font-size: $body-font-size;
  font-weight: 700;
  color: $white;
  display: block;
  &.active {
    color: $secondary;
  }
}

// Sharing
// =================

.navigation__sharing {
  text-align: left;
  display: none;
  border-top: 1px solid darken($medium-gray, 10%);
  padding-top: 1rem;

  h4 {
    color: $white;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .nav-is-open & {
    display: block;
  }

  .navigation__social {
    //opacity: 0.6;
    margin-bottom: 2rem;
    display: flex;
  }
}
