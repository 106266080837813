// Letter Teaser

.letter-teaser {
  background-color: $deep-teal;
  position: relative;

  //padding: 15rem 0 5rem 0;
  //
  //@include breakpoint(medium) {
  //  padding: 20rem 0 8rem 0;
  //}

  &:after {
    content: "";
    background: transparent
      linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #13575703 100%) 0% 0%
      no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    z-index: 0;

    @include breakpoint(large) {
      display: none;
    }
  }
}

.letter-teaser__content {
  padding-top: 30vh;
  padding-bottom: 5vh;

  @include breakpoint(medium) {
    padding-top: 30vh;
    padding-bottom: 10vh;
  }
  @include breakpoint(large) {
    padding-top: 30vh;
    padding-bottom: 10vh;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.letter-teaser__content--container {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  @include breakpoint(medium) {
    margin-top: 0;
    text-align: left;
    width: auto;
  }
}

.letter-teaser__image-container {
  overflow: hidden;
  height: 100%;

  @include breakpoint(medium) {
    padding-top: 20vh;
  }

  @include breakpoint(large) {
    padding-top: 30vh;
    padding-bottom: 20vh;
  }

  img {
    //transform: translateY(10%);
    //max-width: 80%;
    display: block;
    margin: 0 auto;

    @include breakpoint(medium) {
      margin-left: auto;
      transform: translateY(10%);
    }
  }
}

.letter__button {
  margin-top: 2rem;
}
